const { createDsaOrtbObj, hasBadge } = require('relevant-shared/dsa/utils');

const Utils = window.relevantDigital.import('Utils');
const BidderHandler = window.relevantDigital.import('BidderHandler');

/** "Backup" fields in bid.meta we'll use when there are no DSA-objects in bids */
const MetaFields = [
	'advertiserName',
	'advertiserDomains',
	'agencyName',
	'brandName',
];

/** Inject the "badge" html in the creative code, if we find closing tags for <html>/<body>, insert just before that */
const injectBadge = (creativeHtml, badgeHtml) => {
	const lower = creativeHtml.toLowerCase();
	let pos = -1;
	['</html', '</body'].forEach((endStr) => {
		const idx = lower.lastIndexOf(endStr);
		if (idx >= 0) {
			pos = idx;
		}
	});
	if (pos >= 0) {
		return creativeHtml.slice(0, pos) + badgeHtml + creativeHtml.slice(pos);
	}
	return creativeHtml + badgeHtml;
};

class Transparency {
	constructor({ auction }) {
		Utils.assign(this, {
			auction,
			...auction.data.rlvTransparency,
		});
	}

	/** Initialization below will only be done for the first auction. This doesn't account for the edge-case
	 * where different configurations are used with different DSA-settings during the same page-view.  */
	initialize() {
		if (Transparency.initialized) {
			return;
		}
		Transparency.initialized = true;
		const {
			badge, dsa, auction, dsarequired, enforceDsa,
		} = this;
		const { pbRequester } = auction;
		if (badge) {
			pbRequester.getCmpData('tcf'); // start "preloading" this as we might need it later
		}
		if (!dsa) {
			return;
		}
		// Set DSA-object according to:
		// https://github.com/InteractiveAdvertisingBureau/openrtb/blob/main/extensions/community_extensions/dsa_transparency.md
		Utils.merge(pbRequester.prebidConfig, { ortb2: createDsaOrtbObj(this) });

		// Possibly start rejecting bids without DSA-objects
		if (enforceDsa && (dsarequired === '2' || dsarequired === '3')) {
			pbRequester.pbjs.que.push(() => {
				const { getHook } = pbRequester.providedObjects;
				if (getHook) {
					getHook('addBidResponse')?.before(function (next, adUnitCode, bid, reject, ...rest) {
						if (!bid.meta?.dsa && typeof reject === 'function') {
							reject('DSA info missing');
						} else {
							next.call(this, adUnitCode, bid, reject, ...rest);
						}
					});
				}
			});
		}
	}

	/** Returns the final HTML for the "badge" with {{macros}} expanded */
	getBadgeMarkup(obj, bid) {
		const { uiSettings, auction } = this;
		const encode = (o) => btoa(JSON.stringify(o));
		const macros = { ...uiSettings };
		macros.link += encode(obj);
		let html = uiSettings.badgeHtml;

		// For the macro {{encoded}} we'll ad the divId as well. Purpose is that it should be possible
		// to do add something like this in the html: <a onclick="op.postMessage('{{encoded}}', '*' )" ...
		// and that it should be possible to find the right div/placement from the main window code
		if (html.indexOf('{{encoded}}')) {
			const unitData = Utils.find(auction.usedUnitDatas, ({ code }) => code === bid.adUnitCode);
			macros.encoded = encode({
				...obj,
				divId: unitData?.slot.getSlotElementId(),
			});
		}
		const replFn = html.replaceAll || html.replace;
		Utils.entries(macros).forEach(([k, v]) => {
			html = replFn.call(html, `{{${k}}}`, `${v}`);
		});
		return html;
	}

	/** Called for every bid returned in auction */
	processBid(bid) {
		const {
			uiSettings, letAdRender, auction,
		} = this;
		if (!hasBadge(this)) {
			return;
		}
		const { ad, mediaType } = bid;
		if (mediaType !== 'banner' || !ad || !Utils.isString(ad)) {
			return;
		}
		const meta = bid.meta || {};
		const tcfData = auction.pbRequester.getCmpData('tcf');
		const obj = {
			...meta.dsa,
			ssp: BidderHandler.of(bid)?.friendly,
			uiSettings: {
				logoUrl: uiSettings.logoUrl,
				target: uiSettings.target,
				// If there is a CMP and NO consent for purpose 4 "Use profiles to select personalised advertising"
				// then only show "Basic advertising", else "Profiling" - when there is no DSA object.
				defaultDsaParam: tcfData && !tcfData.purpose?.consents?.[4] ? 2 : 1,
			},
		};
		if (!obj.paid && !obj.behalf) { // Use non-DSA data
			MetaFields.forEach((fld) => {
				if (meta[fld]) {
					obj[fld] = meta[fld];
				}
			});
		}
		if (!(letAdRender && meta.dsa?.adrender)) {
			bid.ad = injectBadge(ad, this.getBadgeMarkup(obj, bid));
		}
	}
}

window.relevantDigital.export({ Transparency });
