/** File is included in tag .js, don't require unnecessary stuff here! */

// Create bid request DSA object, see:
// https://github.com/InteractiveAdvertisingBureau/openrtb/blob/main/extensions/community_extensions/dsa_transparency.md
const createDsaOrtbObj = ({
	dsa, dsarequired, datatopub, letAdRender, transparency,
}) => {
	if (!dsa) {
		return undefined;
	}
	const required = parseInt(dsarequired, 10) || 0;
	return {
		regs: {
			ext: {
				dsa: {
					required,
					dsarequired: required, // Slight confusion here, but seems good to add required using this name as well
					pubrender: letAdRender ? 1 : 2,
					datatopub: parseInt(datatopub, 10) || 0,
					transparency: transparency.map((obj) => ({
						domain: obj.domain,
						params: [1, 2, 3].filter((p) => obj[`param${p}`]),
					})),
				},
			},
		},
	};
};

const hasBadge = ({ badge, uiSettings }) => !!(badge && uiSettings.badgeHtml);

module.exports = {
	createDsaOrtbObj,
	hasBadge,
};
